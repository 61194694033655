import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Sparkling Clean Homes
			</title>
			<meta name={"description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:title"} content={"Sparkling Clean Homes"} />
			<meta property={"og:description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/pngtree-abstract-hexagonal-background-icon-png-image_6276075.png?v=2024-06-19T11:48:50.381Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11:44:30.608Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-6.jpg?v=2024-06-19T11%3A44%3A30.608Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Послуги
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						У Sparkling Clean Homes ми пишаємося тим, що пропонуємо широкий спектр послуг з прибирання найвищого рівня, розроблених для задоволення різноманітних потреб наших клієнтів. Наша місія полягає в тому, щоб ваш дім був осередком чистоти та комфорту. Незалежно від того, чи потрібне вам швидке прибирання чи глибоке чищення, наша професійна команда готова забезпечити виняткові результати, які перевищать ваші очікування.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
			color="--darkL1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Що ми пропонуємо
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Наші послуги спрямовані на кожен аспект прибирання будинку. Ми розуміємо, що чистий дім – це щасливий дім, і прагнемо надавати послуги найвищого рівня, щоб ваш житловий простір залишався бездоганним. Нижче наведено огляд основних послуг, які ми пропонуємо:
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Регулярне прибирання
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наші регулярні послуги з прибирання ідеально підходять для підтримки чистоти та порядку в домі. Ці послуги включають:
Очистка від пилу та полірування: Ретельне очищення від пилу всіх поверхонь, включаючи меблі, полиці та декор, з подальшим поліруванням до блиску.
 Житлові зони: Прибирання та прибирання віталень, сімейних кімнат і обідніх зон.
 Спальні: застеляти ліжка, витирати пил з меблів і впорядковувати безлад.
Прибирання пилососом і прибирання: Комплексний догляд за підлогою, щоб ваші килими, доріжки та тверда підлога залишалися чистими.
 Килими та доріжки: Глибоке пилосос для видалення бруду та алергенів.
 Тверда підлога: Миття та полірування для блискучого покриття.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Глибоке очищення
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Для більш ретельного прибирання наші послуги глибокого прибирання займаються ділянками, на які часто не звертають уваги. Ці послуги включають:
Глибоке очищення кухні: Детальне очищення всіх кухонних поверхонь і приладів.
 Чищення побутової техніки:  Внутрішнє та зовнішнє очищення духовок, холодильників і мікрохвильових печей.
 Очищення шафи та стільниці:  Видалення бруду та дезінфекція поверхонь.
Глибоке очищення ванної кімнати: Забезпечення гігієнічності та свіжості кожного куточка вашої ванної кімнати.
 Очищення плитки та розчину:  Видалення плісняви ​​та накопичень.
 Поліровка приладдя:  Блискучі раковини, змішувачі та дзеркала.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Прибирання при заїзді/виїзді
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Зміна будинків може бути стресом. Наші послуги з прибирання при заїзді/виїзді забезпечують бездоганний досвід. Ці послуги включають:
Повне прибирання будинку: Ретельне прибирання всього будинку, щоб підготуватися до наступних мешканців.
 Усі кімнати: Детальне прибирання кожної кімнати зверху вниз.
 Шафи та склад: Прибирання та впорядкування шаф і місць зберігання.
Дезінфікація: Дезінфекція всіх поверхонь для забезпечення здорового середовища.
 Дезінфекція поверхонь: Використання екологічно чистих продуктів для знищення мікробів.
 Очищення повітря: Покращення якості повітря в приміщенні за допомогою комплексного очищення.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Сезонне прибирання
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Підготуйте свій дім до будь-якої пори року за допомогою наших спеціалізованих послуг із сезонного прибирання. Ці послуги включають:
Весняне прибирання: Ретельне оновлення для зустрічі нового сезону.
 Миття вікон: Зовні та всередині для чіткого огляду.
 Прибирання зовнішньої території:  внутрішні дворики, тераси та вуличні меблі.
Підготовка до свята: Переконайтеся, що ваш дім буде чистим для святкових зустрічей.
 Підготовка кімнат для гостей: Зробіть кімнати для гостей привабливими та комфортними.
 Прибирання декору та прикрас:  Чищення святкових прикрас та прикрас.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Спеціальне прибирання
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Ми також пропонуємо спеціальні послуги з прибирання для унікальних потреб. Ці послуги включають:
Прибирання після будівництва:  Видалення будівельного пилу та сміття, щоб зробити ваше нове приміщення придатним для проживання.
 Вивезення сміття: Забезпечення прибирання всіх будівельних матеріалів.
 Детальне видалення пилу: Видалення дрібних частинок пилу з поверхонь.
Прибирання подій: Прибирання після вечірок і спеціальних заходів.
 Підготовка до заходу: Забезпечення бездоганного стану місця проведення до заходу.
 Очищення після події: повернення простору до початкового стану.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Відкрийте більше
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					І це лише початок! Зв’яжіться з нами та ознайомтеся з усіма нашими пропозиціями. Ми тут, щоб обговорити ваші конкретні потреби та створити індивідуальний план прибирання, який гарантує, що ваш дім залишиться блискучою гаванню.
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});